<template>
    <v-dialog v-if="opendialog"
                v-model="opendialog"                
                width="auto"
                max-width="800"
                class="d-flex"         
                :fullscreen="false"                  
            >
                <v-card>
                    <v-card-title class="d-flex justify-space-between-" style="background-color: #ffb56b">
                        <v-btn color="lightgrey" fab x-small @click="$emit('closecheckout')" left style="float: left;" class="mr-5 mb-2">
                            <v-icon>
                                mdi-close
                            </v-icon>                            
                        </v-btn>
                        <h3>Κλείσιμο Ραντεβού</h3>
                    </v-card-title>
                <v-card-text class="px-0">
                    <div class="mx-4 px-4 mb-4">                        
                    </div>
                    <v-spacer></v-spacer>
                    <!-- <span style="font-size: 12px;" class="ml-8">Επιλεγμένος Θεραπευτής:</span> -->
                    <list-card    
                        class="ma-2"
                        v-if="selectedTherapist"                   
                        :avatar="selectedTherapist.img"
                        :title="selectedTherapist.title"
                        :sub-title="selectedTherapist.subTitle"
                        :rating="selectedTherapist.rating"
                        :ratingCount="selectedTherapist.ratingCount"                        
                        :isVerified="selectedTherapist.isVerified"
                        :isFavorite="selectedTherapist.isFavorite"
                        :readmode="true"
                    />
                    <v-spacer></v-spacer>                    
                    <v-expansion-panels v-model="checkoutpanels" accordion focusable>
                        <v-expansion-panel >
                            <v-expansion-panel-header class="d-flex justify-space-between flex-wrap align-middle">
                                <span style="font-size: 0.8em;">Θεραπευτής & Τοποθεσία</span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content class="pt-3">
                                <v-row>
                                    <v-col cols="12" sm="6" class="pa-0" >
                                        <v-avatar v-if="!loading" size="80" class="mr-6 ml-4">
                                            <v-img
                                                    class=""
                                                    
                                                    :src="opendialog ? profileimg + '?key=tmb' : 'https://ik.imagekit.io/platt4mresources/massageapp/8714556a52021ba3a55c8e7a3547d28c.jpg?updatedAt=1731701496621'"
                                                    :placeholder="'https://ik.imagekit.io/platt4mresources/massageapp/8714556a52021ba3a55c8e7a3547d28c.jpg?updatedAt=1731701496621'"
                                                    alt=""
                                                />
                                        </v-avatar>
                                        <span v-if="details && !loading">
                                            {{ details.firstname + ' ' + details.lastname}}
                                        </span>
                                    </v-col>
                                    <v-col cols="12" sm="6" class="pa-0">
                                        <span class="mt-5"> Επιλεγμένη Τοποθεσία:</span>
                                        <!--:items="['Ομήρου 13 - Νέα Ιωνία', 'Βυζαντίου 37 - Νέος Κόσμος']"  -->
                                        <v-select                                                                     
                                            v-model="address_filter"

                                            :items="myLocations"                 
                                            item-value="code"                            
                                            label=""                                                                 
                                            :rules="[]"     
                                            required                                                         
                                            :clearable="false"    
                                            hide-details=true      
                                            style="padding-top: 0px;"      
                                            @change="addressSelected"                     
                                        />
                                    </v-col>
                                </v-row>                               
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel >
                            <v-expansion-panel-header class="d-flex justify-space-between flex-wrap align-middle">
                                <span style="font-size: 0.8em;">1. Επιλογή Θεραπείας & Διάρκεια</span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-list :v-if="false" two-line>
                                  
                                        <v-list-item v-for="(item, index) in therapistServices.filter(s=>s.selected==true)" :key="item.title" class="px-0">
                                            <template v-slot:default="{ active }">
                                            <v-list-item-content style="font-size: 0.6em !important;">
                                                <v-list-item-title  style="color: black; font-size: 1.4em !important;">
                                                    <v-btn                                                                                                                
                                                        class="mr-1 shadow-sm"
                                                        fab                                                                                                                
                                                        color="lightgrey"   
                                                        x-small
                                                        @click="item.selected=false"                     
                                                    >
                                                        <v-icon>mdi-close</v-icon>
                                                    </v-btn> 
                                                    {{item.title}}
                                                </v-list-item-title>
                                            </v-list-item-content>

                                            <v-list-item-action>
                                                <v-list-item-action-text sh>
                                                    <v-select                                                                     
                                                        v-model="item.selectedDuration"
                                                        :items="item.options.map(o=> { return {value: o.duration, text: o.duration + ' / ' + o.price + '€' } })"                 
                                                        label="Διάρκεια / Κόστος"                                     
                                                        style="padding:0px; width: 120px;"
                                                        :rules="[]"     
                                                        required         
                                                        dense                                                
                                                        :clearable="false"    
                                                        hide-details=true                                                             
                                                    />
                                                </v-list-item-action-text>                                             
                                            </v-list-item-action>                                            
                                            </template>
                                            
                                        </v-list-item>
                                        <v-list-item v-if="therapistServices.filter(s=>s.selected==true).length>0">
                                            <v-list-item-content>&nbsp;</v-list-item-content>
                                            <v-list-item-action-text class="v-list-item__title" style="text-align: right; color: black;">
                                                    Σύνολο: {{total}}€
                                                </v-list-item-action-text>
                                        </v-list-item>
                                </v-list>
                                <v-chip
                                    v-for="(selection, i) in therapistServices.filter(s=>s.selected==false)"
                                    :key="i"
                                    :color="selection.selected ? 'purple' : '#ffb56b'"
                                    dark                                    
                                    class="ma-1"
                                    @click="selection.selected=!selection.selected"
                                    >
                                    <v-icon
                                        left
                                        small
                                    >
                                        mdi-plus
                                    </v-icon>
                                    {{ selection.title }} {{ selection.options.find(o=>o.default).price + '€ / ' + selection.options.find(o=>o.default).duration  }}
                                </v-chip>
                            </v-expansion-panel-content>
                        </v-expansion-panel>

                        <v-expansion-panel >
                            <v-expansion-panel-header class="d-flex justify-space-between flex-wrap align-left pt-0 pb-0">
                                <!-- <div>
                                    <v-chip
                                        class="ma-2 flex-wrap"
                                        
                                        >
                                        Βήμα 2
                                    </v-chip>
                                </div>                             -->
                                <span style="font-size: 0.8em;">2. Ημ/νία & Ώρα Ραντεβού</span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                        <v-menu
                                            v-model="showDateFilterMenuCheckout"
                                            bottom
                                            :offset-y="true"
                                            :close-on-content-click="false"
                                            >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                color="lightgrey"                                                                               
                                                v-bind="attrs"
                                                v-on="on"
                                                class="text-capitalize mb-2 mt-2"
                                                style="width: 100%"
                                                small
                                                @click="()=>{ hourStartFilter = '08:00'; hourEndFilter= '22:00'}"
                                                >
                                                <v-icon
                                                    right
                                                    dark
                                                >
                                                    mdi-calendar
                                                </v-icon>
                                                &nbsp;Επιλέξτε Διαθεσιμότητα
                                                </v-btn>
                                            </template>
                                            <v-card style="min-width: 350px;">
                                                <v-card-text class="text-center">
                                                <div class="text-center">
                                                    <v-date-picker 
                                                                    v-model="date" 
                                                                    no-title 
                                                                    @input="save(date)"
                                                                    :events="datesAll"
                                                                    event-color="blue lighten-1"
                                                                    >
                                                        <v-spacer></v-spacer>                                                        
                                                    </v-date-picker>
                                                    <v-spacer></v-spacer>   
                                                    <v-btn @click="showDateFilterMenuCheckout=false" small color="primary" class="mt-2" style="width: 60%">OK</v-btn>
                                                </div>                                                   
                                                </v-card-text>
                                            </v-card>                                            
                                        </v-menu>























                    <!--<v-radio-group v-model="selectedTimeslot">
                        <table style="width: 100%; text-align: center;">
                        <tbody>
                            <tr>
                                <td  v-for="(selection, i) in datesAll" :key="i">{{getDateString(selection)}}</td>                                
                            </tr>
                            <tr v-for="timeslot in seasons" :key="timeslot">
                                <td class="text-center"  v-for="(selection, i) in datesAll" :key="i" style="text-align: center;">
                                    <v-radio :disabled="timeslot == '02:00' || timeslot == '03:00'" :value="getDateString(selection) + ' ' + timeslot " color="purple" class="ma-0 pa-0" hide-details hide-spin-buttons >
                                        <template v-slot:label>
                                            <span v-if="timeslot == '02:00' || timeslot == '03:00'" style="text-decoration: line-through;">{{timeslot}}</span>
                                            <span v-else style="color: purple">{{timeslot}}</span>
                                        </template>
                                    </v-radio>
                                </td>                                                                
                            </tr>                            
                        </tbody>
                    </table>
                    </v-radio-group>  -->                  
                            </v-expansion-panel-content>




                        </v-expansion-panel>
                            
                        <v-expansion-panel >
                            <v-expansion-panel-header class="d-flex justify-space-between flex-wrap align-left pt-0 pb-0">
                                <span style="font-size: 0.8em;">3. Επισκόπηση & Πληρωμή</span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-row>
                                    <v-col cols="12" sm="6" class="pa-0">
                                        <v-list>
                                            <v-list-item>
                                                <h6 class="mb-0"><v-icon>mdi-map-marker</v-icon>&nbsp;Τοποθεσία</h6>
                                            </v-list-item>
                                            <v-list-item class="pl-4">
                                                <p class="mb-0">{{address_filter}}</p>
                                            </v-list-item>
                                        </v-list>
                                    </v-col>
                                    <v-col cols="12" sm="6" class="pa-0">
                                        <v-list>
                                            <v-list-item>
                                                <h6 class="mb-0"><v-icon>mdi-calendar</v-icon>&nbsp;Ημερομηνία & Ώρα</h6>
                                            </v-list-item>
                                            <v-list-item class="pl-4">
                                                <p class="mb-0">{{selectedTimeslot}}</p>
                                            </v-list-item>
                                        </v-list>
                                    </v-col>
                                </v-row>
                                <v-row class="text-center">
                                    <v-col cols="12" sm="6" class="text-center pa-0">
                                        <v-list>
                                            <v-list-item>
                                                <h6 class="mb-0"><v-icon>mdi-clipboard-list-outline</v-icon>&nbsp;Θεραπείες</h6>
                                            </v-list-item>
                                            <v-list-item class="pl-4">
                                                <p>
                                                    <v-chip
                                                        v-for="(selection, i) in therapistServices.filter(s=>s.selected==true)"
                                                        :key="i"
                                                        :color="'#ffb56b'"
                                                        
                                                        dark         
                                                        small                           
                                                        class="pr-5 mr-1 mb-1"                                                    
                                                        >                                                    
                                                        {{ selection.title }} {{ selection.options.find(o=>o.duration == selection.selectedDuration).price + '€ / ' + selection.options.find(o=>o.default).duration  }}
                                                    </v-chip>
                                                </p>                                                
                                            </v-list-item>
                                        </v-list>
                                    </v-col>
                                    <v-col cols="12" sm="6" class="pa-0">
                                        <v-list>
                                            <v-list-item>
                                                <h6 class="mb-0"><v-icon>mdi-cash-register</v-icon>&nbsp;Κόστος Υπηρεσιών</h6>
                                            </v-list-item>
                                            <v-list-item>
                                                <span class="mb-0">&nbsp;Το πληρώνετε στον θεραπευτή</span>
                                            </v-list-item>
                                            <v-list-item class="pl-4">
                                                <h4 class="mb-0">{{total}}€</h4>
                                            </v-list-item>
                                            <v-list-item>
                                                <h6 class="mb-0"><v-icon>mdi-cash-register</v-icon>&nbsp;Κόστος Κράτησης</h6>
                                            </v-list-item>
                                            <v-list-item>
                                                <span class="mb-0">&nbsp;Το πληρώνετε ηλεκτρονικά</span>
                                            </v-list-item>
                                            <v-list-item class="pl-4">
                                                <h4 class="mb-0">{{total}}€</h4>
                                            </v-list-item>
                                        </v-list>
                                    </v-col>
                                    <v-col cols="12" class="pa-0">
                                        <v-list>
                                            <v-list-item class="d-flex justify-center">
                                                <span>Πληρώστε με:</span>
                                            </v-list-item>
                                            <v-list-item class="pl-4 d-flex justify-center flex-column">
                                                
                                                <v-btn color="primary" dark class="text-capitalize" width="320">
                                                    <v-icon>mdi-credit-card-fast-outline</v-icon>
                                                    &nbsp;&nbsp;&nbsp;Χρεωστική / Πιστωτική Κάρτα
                                                </v-btn>
                                                <br/>
                                                <v-btn color="black" dark class="text-capitalize" width="320">
                                                    <v-icon>mdi-bank-outline</v-icon>
                                                    &nbsp;&nbsp;&nbsp;Μεταφορά σε τράπεζα
                                                </v-btn>
                                                <br/>
                                                <v-btn color="#595959" dark class="text-capitalize" width="320">
                                                    <v-icon>mdi-account-cash-outline</v-icon>
                                                    &nbsp;&nbsp;&nbsp;Μετρητά 
                                                </v-btn>
                                            </v-list-item>
                                        </v-list>
                                    </v-col>
                                </v-row>
                                
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>                    
                </v-card-text>
                <!-- <v-card-actions>
                    <v-btn color="primary" block @click="dialog = false">Close Dialog</v-btn>
                </v-card-actions> -->
                </v-card>
            </v-dialog>
</template>
<script>
import avatarGroupCard from '@/components/card/AvatarGroupCard';
import { defaultTo } from 'lodash';
import {VueperSlides, VueperSlide} from 'vueperslides';
import 'vueperslides/dist/vueperslides.css';
import {api, srvApi, srvApiNoAuth, PATH, getAssetUrl} from "src/api/index";
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
    name: 'cart',
    metaInfo: {
        // title will be injected into parent titleTemplate
        //title: 'Profile 1'
    },
    components: {
        avatarGroupCard,
        VueperSlides, 
        VueperSlide 
    },
    props: {
        initdetails: {
            type: Object,
            default: null
        },        
        opendialog: {
            type: Boolean,
            default: false
        },
        therapist_code: {
            type: String,
            default: null
        },
        selectedaddress: {
            type: Object,
            default: null
        }
    },
    watch: {
        async slotrange(newvalue) {            
            const rspTherapist = await srvApi.get(PATH.BOOKING_THERAPIST + "/" + this.therapist_code + "?offset=" + this.cal_offset + "&range=" + this.slotrange );
            this.details = rspTherapist.data;
            this.$forceUpdate();
        },
        async cal_offset(newvalue) {
            const rspTherapist = await srvApi.get(PATH.BOOKING_THERAPIST + "/" + this.therapist_code + "?offset=" + this.cal_offset + "&range=" + this.slotrange );
            this.details = rspTherapist.data;
            this.$forceUpdate();
        },
        async opendialog(vl) {
            if (vl) {       
                try {
                    this.loading = true;
                    this.checkoutpanels = 0;
                    const selectedTherapistCode = this.getCartTherapist;        
                    if(selectedTherapistCode) {            
                        const rspTherapist = await srvApi.get(PATH.BOOKING_THERAPIST + "/" + selectedTherapistCode + "?offset=" + this.cal_offset + "&range=" + this.slotrange );
                        this.details = rspTherapist.data;        
                    }
                } catch (error) {
                    console.log(error);
                } finally {
                    this.loading = false;
                }                   
            }            
        },        
    },
    computed: {
        ...mapGetters(['refSystemServiceGroups', 'refSystemServices', 
                'myLocations','myCurrentLocation',      "getCart", "getCartList", "getCartTherapist"]),        
        address_filter() {
            const d = this.selectedaddress;
            return d;
        },
        slotrange() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return 2
                case 'sm': return 2
                case 'md': return 3
                case 'lg': return 7
                case 'xl': return 7
            }
        },
        profileimg() {
            if(this.details) {
                return getAssetUrl(this.details.profile_photo);
            }            
            return "";
        },

        serviceSelection() {
            if(this.details && this.details.services) {
                let rslt = this.details.services.map(s=>({...s, selected: this.getCartList.find(a=>a.code == s.code) != null }));
                return rslt;
            }
            else {
                return [];
            }
        }
    },
    data() {
        return {      
            loading: true,      
            //address_filter: null,            
            selectedTherapist: null,
            therapistServices: [],
            

            checkoutpanels: 0,

            //selectedTimeslot: null,
            showDateFilterMenuCheckout: false,
            date: null,
            datesAll: null,
            total: null,





            details: null,
            selectedTimeslot: null,            
            cal_offset: 0,
            cal_range: 7,            

            help_rating: 4,


            therapistServices: [
                {
                    title: "Μασάζ Προσώπου",                    
                    options: [
                        { //€
                            price: "15",
                            duration: "30'",
                            default: false,
                        },
                        { //€
                            price: "30",
                            duration: "60'",
                            default: true,
                        }
                    ],
                    selected: false,
                    selectedDuration: "60'"
                },
                {
                    title: "Μασάζ Πλάτης",
                    options: [
                        { //€
                            price: "20",
                            duration: "30'",
                            default: false,
                        },
                        { //€
                            price: "20",
                            duration: "60'",
                            default: true,
                        }
                    ],
                    selected: false,
                    selectedDuration: "60'"
                },
                {
                    title: "Μασάζ Αυχένα",
                    options: [
                        { //€
                            price: "10",
                            duration: "15'",
                            default: false,
                        },
                        { //€
                            price: "20",
                            duration: "30'",
                            default: true,
                        }
                    ],
                    selected: false,
                    selectedDuration: "30'"
                },
                {
                    title: "Shiatsu",
                    options: [
                        { //€
                            price: "10",
                            duration: "15'",
                            default: false,
                        },
                        { //€
                            price: "20",
                            duration: "30'",
                            default: true,
                        }
                    ],
                    selected: false,
                    selectedDuration: "30'"
                },
                {
                    title: "Λεμφικό Μασάζ",
                    options: [
                        { //€
                            price: "30",
                            duration: "30'",
                            default: false,
                        },
                        { //€
                            price: "40",
                            duration: "60'",
                            default: true,
                        },
                        { //€
                            price: "60",
                            duration: "90'",
                            default: false,
                        }                    
                    ],
                    selected: false,
                    selectedDuration: "60'"
                },
            ],


                                   
        }
    },
    methods: {
        ...mapActions(["addToCart", "setCartTherapist", "removeFromCart"]),
        ...mapMutations(['setMyCurrentLocation']),
        addressSelected: function(loca) {
            const selectedLocation = this.myLocations.find(x=>x.code==loca);
            if(selectedLocation) {
                this.setMyCurrentLocation(selectedLocation);   
            }            
        },
        toggleSelectService: function(service) {
            console.log(JSON.stringify(service, null, 2));
            if(this.getCartList.find(s=> s.code == service.code) == null) {
                this.addToCart(service);
            }
            else {
                this.removeFromCart(service);
            }            
        },
        getDateString: function(dt_string) {
            var weekday=new Array(7);
            weekday[1]="Mon";
            weekday[2]="Tue";
            weekday[3]="Wed";
            weekday[4]="Thu";
            weekday[5]="Fri";
            weekday[6]="Sat";
            weekday[0]="Sun";
            
            var dt = new Date(dt_string);
            const dayWeek = dt.getUTCDay();
            
            return `${weekday[dayWeek]}, ${dt.getUTCDate()}/${dt.getUTCMonth()+1}`;
        }
    },
    async mounted() {        

        // if(this.details == null && this.therapist_code != null) {
        //     const rspTherapist = await srvApi.get(PATH.BOOKING_THERAPIST + "/" + this.therapist_code + "?offset=" + this.cal_offset + "&range=" + this.cal_range );
        //     this.details = rspTherapist.data;
        //     // this.selectedTherapist = [rspTherapist.data].map(t=>({
        //     //     img: getAssetUrl(t.profile_photo),
        //     //     title: t.firstname + " " + t.lastname,
        //     //     subTitle: t.short_description,
        //     //     progressLinearColor: 'primary',
        //     //     rating: t.rating,
        //     //     ratingCount: t.rating_count,
        //     //     details: {...t}
        //     // }))[0];
        // }        
    },
    async created() {
        
    }
}
</script>
<style lang="scss">

.slotpicker-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.slotpicker-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    max-height: 400px;
}

.slotpicker-column {
    height: 100%;
    padding: 5px;
    //background-color: red;
    margin: 5px;
    flex-grow: 1;
}

.slotpicker-side {
    width: 40px;
    height: 100%;
    padding: 5px;
    //background-color: red;
    margin: 5px;
}

.slotpicker-weekday {
    text-align: center;
}

.slotpicker-date {
    text-align: center;
}

.slotpicker-slotcolumn {
    text-align: center;
}

</style>
