<template>
    <div style="min-width: 100%">
        <v-sheet  class="px-0 py-0 align-center" style="background-color: transparent;">
            <v-row class="d-flex justify-center" >
                    <v-col cols="12" sm="12" lg="7">
                        <base-card>
                            <v-card-text>
                                <v-row>
                                    <v-col>



                                        <!--:items="['Ομήρου 13 - Νέα Ιωνία', 'Βυζαντίου 37 - Νέος Κόσμος']"-->
                                        <v-select                                                                     
                                            v-model="address_filter"
                                            item-value="code"                                            
                                            :items="myAugmentedLocations"
                                            label="Διεύθυνση"                                     
                                            style="padding-right: 20px; padding-left: 20px; padding-top: 0px; padding-bottom: 0px; "
                                            :rules="[]"     
                                            required                                                         
                                            :clearable="false"    
                                            hide-details=true     
                                            @change="addressSelected"      
                                        />
                                        
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="6" class="d-flex justify-start flex-column">                                                                                
                                        <v-menu
                                            bottom
                                            :offset-y="true"
                                            :close-on-content-click="false"
                                            v-model="showTreatmentFilterMenu"
                                            >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                color="#b1b1b1"   
                                                dark                                             
                                                v-bind="attrs"
                                                v-on="on"
                                                class="text-capitalize mb-2"
                                                small
                                                >
                                                <v-icon>mdi-clipboard-list-outline</v-icon>&nbsp;Επιλέξτε Θεραπεία
                                                </v-btn>
                                            </template>

                                            <v-card style="width: 100%;">
                                                <v-card-text>          
                                                    <div style="width: 100%; min-height: 500px; max-height: 500px; display: flex; flex-direction: row; font-size: 0.5em;">
                                                        
                                                        <div style="width: 40%; min-height: 500px;">
                                                            <vue-perfect-scrollbar
                                                                :settings="{ suppressScrollX: true, wheelPropagation: false }"
                                                                class="h-100 rtl-ps-none ps scroll"
                                                                style="height: 100%"
                                                            >
                                                                <v-list dense>
                                                                    <v-subheader>Κατηγορίες</v-subheader>
                                                                    <v-list-item-group>
                                                                        <v-list-item @click="selectedCategory=category.value" v-for="category in columnSelector_Categories" :key="category.value">
                                                                            <v-list-item-content><v-list-item-title>{{ category.text }}</v-list-item-title></v-list-item-content>
                                                                            <v-list-item-icon v-if="selectedCategory == category.value"><v-icon>mdi-chevron-right</v-icon></v-list-item-icon>
                                                                        </v-list-item>                                                                    
                                                                    </v-list-item-group>
                                                                </v-list>
                                                            </vue-perfect-scrollbar>                                                            
                                                        </div>
                                                        <div style="width: 60%; min-height: 500px; max-height: 500px; "> 
                                                            <vue-perfect-scrollbar
                                                                :settings="{ suppressScrollX: true, wheelPropagation: false }"
                                                                class="h-100 rtl-ps-none ps scroll"
                                                                style="height: 100%"
                                                            >
                                                                <v-list dense>
                                                                    <v-subheader>Υπηρεσίες</v-subheader>
                                                                    <v-list-item-group>
                                                                        <v-list-item @click="addServiceFilter(service.value)" v-for="service in columnSelector_Services" :key="service.value">
                                                                            <v-list-item-content><v-list-item-title>{{ service.text }}</v-list-item-title></v-list-item-content>
                                                                            <v-list-item-icon v-if="selectedServices.includes(service.value)"><v-icon>mdi-check</v-icon></v-list-item-icon>
                                                                        </v-list-item>
                                                                    </v-list-item-group>
                                                                </v-list>
                                                            </vue-perfect-scrollbar>                                                                     
                                                        </div>
                                                    </div>                                                                                         
                                                    <!--<v-treeview
                                                        v-model="tree"      
                                                        :load-children="fetch"                                          
                                                        :items="items2"
                                                        selected-color="indigo"
                                                        open-on-click
                                                        selectable
                                                        return-object
                                                        expand-icon="mdi-chevron-down"
                                                        on-icon="mdi-checkbox-marked"
                                                        off-icon="mdi-checkbox-blank-outline"
                                                        indeterminate-icon="mdi-checkbox-multiple-marked-outline"
                                                    >
                                                    </v-treeview>-->
                                                    <div style="width: 100%; " class="text-center">
                                                        <v-btn @click="showTreatmentFilterMenu=false" small color="primary" class="mt-2" style="width: 60%">OK</v-btn>
                                                    </div>                                                    
                                                </v-card-text>
                                            </v-card>                                            
                                        </v-menu>
                                        <!-- <br/> -->
                                        <div v-if="selectedServicesItems.length" style="max-width: 100%;">
                                            <v-chip                                            
                                            v-for="selection in selectedServicesItems"
                                            :key="selection.value"
                                            @click="addServiceFilter(selection.value)"
                                            color="grey"
                                            dark
                                            small
                                            class="ma-1 visibleonhoverr_container pr-6"
                                            >
                                            <v-icon
                                                left
                                                small
                                                class="visibleonhoverr-"
                                            >
                                                mdi-window-close
                                            </v-icon>
                                            {{ selection.text }}
                                            </v-chip>
                                        </div>
                                    </v-col>
                                    <v-col cols="12" sm="6" class="d-flex justify-start flex-column">                                        
                                        <v-menu
                                            v-model="showDateFilterMenu"
                                            bottom
                                            :offset-y="true"
                                            :close-on-content-click="false"
                                            >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                color="#b1b1b1" 
                                                dark
                                                v-bind="attrs"
                                                v-on="on"
                                                class="text-capitalize mb-2"
                                                style="width: 100%"
                                                small
                                                @click="()=>{ hourStartFilter = '08:00'; hourEndFilter= '22:00'}"
                                                >
                                                <v-icon>mdi-calendar</v-icon>&nbsp;Επιλέξτε Ημερομηνία & Ώρα
                                                </v-btn>
                                            </template>

                                            <!-- <v-list>
                                                <v-list-item
                                                v-for="(item, index) in items"
                                                :key="index"
                                                >
                                                <v-list-item-title>{{ item.title }}</v-list-item-title>
                                                </v-list-item>
                                            </v-list> -->
                                            <v-card style="min-width: 350px;">
                                                <v-card-text class="text-center">
                                                <div class="text-center">
                                                    <v-date-picker 
                                                                    v-model="date" 
                                                                    no-title 
                                                                    @input="save(date)"
                                                                    :events="datesAll"
                                                                    event-color="blue lighten-1"
                                                                    >
                                                        <v-spacer></v-spacer>                                                        
                                                    </v-date-picker>
                                                    <v-select                                                                     
                                                        v-model="hourStartFilter"
                                                        :items="seasons"                 
                                                        label="Ώρα Από"                                     
                                                        style="padding-right: 20px; padding-left: 20px; "
                                                        :rules="[]"     
                                                        required                                                         
                                                        :clearable="false"    
                                                        hide-details=true            
                                                    />
                                                    <v-select                                                                     
                                                        v-model="hourEndFilter"
                                                        :items="seasons"                 
                                                        label="Εώς"                                     
                                                        style="padding-right: 20px; padding-left: 20px; "
                                                        :rules="[]"     
                                                        required                                                         
                                                        :clearable="false"    
                                                        hide-details=true            
                                                    />
                                                    <v-spacer></v-spacer>   
                                                    <v-btn @click="showDateFilterMenu=false" small color="primary" class="mt-2" style="width: 60%">OK</v-btn>
                                                </div>
                                                    <!-- <v-range-slider
                                                    :tick-labels="seasons"
                                                    :value="[0, 1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24]"
                                                    min="0"
                                                    max="24"
                                                    ticks="always"
                                                    tick-size="4"
                                                    class="mt-4"
                                                >
                                                    <template v-slot:thumb-label="props">
                                                        {{props.value}}
                                                    <v-icon dark>
                                                        mdi-clock
                                                    </v-icon>
                                                    </template>
                                                </v-range-slider> -->
                                                    <!-- <v-treeview
                                                        v-model="tree"      
                                                        :load-children="fetch"                                          
                                                        :items="items2"
                                                        selected-color="indigo"
                                                        open-on-click
                                                        selectable
                                                        return-object
                                                        expand-icon="mdi-chevron-down"
                                                        on-icon="mdi-checkbox-marked"
                                                        off-icon="mdi-checkbox-blank-outline"
                                                        indeterminate-icon="mdi-checkbox-multiple-marked-outline"
                                                    >
                                                    </v-treeview> -->
                                                </v-card-text>
                                            </v-card>                                            
                                        </v-menu>
                                        <!-- <br/> -->
                                        <div v-if="datesAll.length" style="max-width: 100%; text-align: center;">
                                            <v-chip
                                                v-for="(selection, i) in datesAll"
                                                :key="i"
                                                color="grey"
                                                dark
                                                small
                                                class="ma-1"
                                                >
                                                <v-icon
                                                    left
                                                    small
                                                >
                                                    mdi-calendar-outline
                                                </v-icon>
                                                {{ getDateString(selection) }}
                                            </v-chip>
                                        </div>
                                        <!-- <br/> -->
                                        <div v-if="hourStartFilter || hourEndFilter " style="max-width: 100%; text-align: center;">
                                            <v-chip 
                                                v-if="hourStartFilter"                                                                                               
                                                color="purple"
                                                dark
                                                small
                                                class="ma-1"
                                                >
                                                <v-icon
                                                    left
                                                    small
                                                >
                                                    mdi-clock
                                                </v-icon>
                                                {{ hourStartFilter }}
                                            </v-chip>
                                            -
                                            <v-chip                                                                                                
                                                v-if="hourEndFilter"                                                                                               
                                                color="purple"
                                                dark
                                                small
                                                class="ma-1"
                                                >
                                                <v-icon
                                                    left
                                                    small
                                                >
                                                    mdi-clock
                                                </v-icon>
                                                {{ hourEndFilter }}
                                            </v-chip>
                                        </div>
                                        
                                    </v-col>                                    
                                </v-row>
                            </v-card-text>
                        </base-card>                        
                    </v-col>
                </v-row>
            <div class="container- d-flex justify-center pl-4 pr-4">                
                <v-row class="d-flex justify-center">
                    <v-col cols="12">
                            <v-data-iterator                            
                :items="items"
                :items-per-page.sync="itemsPerPage"
                :page="page"
                hide-default-footer
                no-data-text="Δεν βρέθηκαν Θεραπευτές. Δοκιμάστε με διαφορετικά κριτήρια αναζήτησης"
            >
                <template v-slot:default="props">
                    <v-row class=" d-flex justify-center">
                        <v-col

                            v-for="(item, index) in props.items"
                            :key="index"
                            cols="12"
                            sm="12"
                            md="6"                            
                            lg="3"
                            xl="3"
                        >

                            <list-card
                                @clickcard=" selectedTherapistCode=props.items[index].details.code; profilepreviewdialog=true;"
                                @clickbook=" initiateCheckoutFlow(props.items[index].details.code)"
                                :avatar="item.img"
                                :title="item.title"
                                :sub-title="item.subTitle"                                
                                :rating="item.rating"
                                :ratingCount="item.ratingCount"
                                :isVerified="item.isVerified"
                            />


                        </v-col>
                    </v-row>
                </template>

            </v-data-iterator>
                    </v-col>
 
                </v-row>
                
            </div>
            <Cart :opendialog="checkoutdialog" :selectedaddress="address_filter" @closecheckout="checkoutdialog=false"></Cart>
            <v-dialog
            v-if="profilepreviewdialog"
            v-model="profilepreviewdialog"                
                width="auto"
            >
                <v-card>
                <v-card-text>                        
                    <profile-details :therapist_code="selectedTherapistCode" :previewMode="false" @clickclose="profilepreviewdialog=false" @clickbook="initiateCheckoutFlow(selectedTherapistCode)"></profile-details>              
                </v-card-text>
                <!-- <v-card-actions>
                    <v-btn color="primary" block @click="dialog = false">Close Dialog</v-btn>
                </v-card-actions> -->
                </v-card>
            </v-dialog>
        </v-sheet>     
        <v-dialog
            v-model="addNewLocationDialog"
            max-width="900px"
          >
            <v-card>
                <v-card-title>
                    <span class="text-h5">Προσθήκη Νέας Διεύθυνσης</span>
                    <div style="flex-grow:1">&nbsp;</div>
                    <v-btn dark small fab style="background-color: #222; color: white;" @click="addNewLocationDialog=false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <LocationEditor  :index="-1" :loading_setDefaultLocation="loading_setDefaultLocation" v-model="newLocation" @set-default="setDefaultLocation"/>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn                    
                    color="black darken-1"
                    text
                    @click="addNewLocationDialog=false"
                    >
                        ΚΛΕΙΣΙΜΟ
                    </v-btn>
                    <v-btn
                    style="background-color: #efa165;"
                    color="white"
                    dark
                    text
                    @click="saveNewLocation"
                    >
                        ΑΠΟΘΗΚΕΥΣΗ
                    </v-btn>
              </v-card-actions>
            </v-card>            
        </v-dialog>   
    </div>
</template>
<script>
import {mapGetters, mapActions, mapMutations} from 'vuex'
import avatarGroupCard from '@/components/card/AvatarGroupCard';
import {VueperSlides, VueperSlide} from 'vueperslides';
import 'vueperslides/dist/vueperslides.css';
import ListCardThree from '@/components/card/listCard/ListCardThree';
import ProfileDetails from '@/views/mng/home/profiledetails';
import Cart from '@/views/mng/home/cart';

import {api, srvApi, srvApiNoAuth, PATH, getAssetUrl} from "src/api/index";

import GoogleMapX from "@/components/core/GoogleMapX";

import ListCard from '@/components/card/listCard/ListCard';

import TherapistListCard from "@/components/home/TherapistListCard";
import LocationEditor from "@/components/home/LocationEditor";

export default {
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Find your Session'
    },
    components: {
        avatarGroupCard,
        VueperSlides, 
        VueperSlide,
        GoogleMapX,
        'list-card-three': ListCardThree,
        'list-card': TherapistListCard,
        'profile-details': ProfileDetails,
        LocationEditor,
        Cart
    },
    



    data() {
        return {
            previousAddressFilter: null,
            loading_setDefaultLocation: false,
            addNewLocationDialog: false,
            newLocation: null,
            //columnSelector_Categories: [],
            selectedCategory: null,
            //columnSelector_Services: [],            
            selectedServices: [],

            therapistServices: [
                {
                    title: "Μασάζ Προσώπου",                    
                    options: [
                        { //€
                            price: "15",
                            duration: "30'",
                            default: false,
                        },
                        { //€
                            price: "30",
                            duration: "60'",
                            default: true,
                        }
                    ],
                    selected: false,
                    selectedDuration: "60'"
                },
                {
                    title: "Μασάζ Πλάτης",
                    options: [
                        { //€
                            price: "20",
                            duration: "30'",
                            default: false,
                        },
                        { //€
                            price: "20",
                            duration: "60'",
                            default: true,
                        }
                    ],
                    selected: false,
                    selectedDuration: "60'"
                },
                {
                    title: "Μασάζ Αυχένα",
                    options: [
                        { //€
                            price: "10",
                            duration: "15'",
                            default: false,
                        },
                        { //€
                            price: "20",
                            duration: "30'",
                            default: true,
                        }
                    ],
                    selected: false,
                    selectedDuration: "30'"
                },
                {
                    title: "Shiatsu",
                    options: [
                        { //€
                            price: "10",
                            duration: "15'",
                            default: false,
                        },
                        { //€
                            price: "20",
                            duration: "30'",
                            default: true,
                        }
                    ],
                    selected: false,
                    selectedDuration: "30'"
                },
                {
                    title: "Λεμφικό Μασάζ",
                    options: [
                        { //€
                            price: "30",
                            duration: "30'",
                            default: false,
                        },
                        { //€
                            price: "40",
                            duration: "60'",
                            default: true,
                        },
                        { //€
                            price: "60",
                            duration: "90'",
                            default: false,
                        }                    
                    ],
                    selected: false,
                    selectedDuration: "60'"
                },
            ],
            selectedTherapistServices: [],

            selectedTimeslot: null,            

            checkoutdialog: false,
            profilepreviewdialog: false,
            breweries: [], 
            isLoading: false,
            tree: [],
            //types: ['Μασάζ','Φυσιοθεραπεία', 'Ρεφλεξολογία'],

            hourStartFilter: null,
            hourEndFilter: null,

            showTreatmentFilterMenu: false,
            showDateFilterMenu: false,
            showDateFilterMenuCheckout: false,

            selectedTherapist: null,
            selectedTherapistCode: null,            

            date: null,
            datesAll: [],

            seasons: ['00:00','01:00','02:00','03:00','04:00','05:00','06:00','07:00','08:00','09:00','10:00','11:00','12:00','13:00','14:00','15:00','16:00','17:00','18:00','19:00','20:00','21:00','22:00','23:00','24:00'],


            address_filter: null,


            itemsPerPageArray: [],
            search: '',
            filter: {},
            sortDesc: false,
            page: 1,
            itemsPerPage: 1000,
            sortBy: 'name',
            keys: [
                'Name',
                'Calories',
                'Fat',
                'Carbs',
                'Protein',
                'Sodium',
                'Calcium',
                'Iron'
            ],
            items: [

                

                /*{
                    img: require('@/assets/images/avatars/001-man.svg'),
                    title: 'Timothy clarkson',
                    subTitle: '',
                    progressLinearColor: 'primary',
                    rating: 3,
                    ratingCount: 34,
                },*/
                /*{
                    img: require('@/assets/images/avatars/002-woman.svg'),
                    title: 'Zac Clarkson',
                    subTitle: '',
                    progressLinearColor: 'success',
                    isVerified: true
                },
                {
                    img: require('@/assets/images/avatars/003-man-1.svg'),
                    title: 'Zac Clarkson',
                    subTitle: 'I am a therapist specialized in athletic massage.',
                    progressLinearColor: 'danger',
                    rating: 3,
                },
                {
                    img: require('@/assets/images/avatars/007-woman-2.svg'),
                    title: 'Timothy clarkson',
                    subTitle: '',
                    progressLinearColor: 'primary'
                },
                {
                    img: require('@/assets/images/avatars/006-woman-1.svg'),
                    title: 'Zac Clarkson',
                    subTitle: '',
                    progressLinearColor: 'success'
                },
                {
                    img: require('@/assets/images/avatars/003-man-1.svg'),
                    title: 'Zac Clarkson',
                    subTitle: '',
                    progressLinearColor: 'danger',
                    rating: 2,
                    ratingCount: 0,
                },
                {
                    img: require('@/assets/images/faces/1.jpg'),
                    title: 'Timothy clarkson',
                    subTitle: '',
                    progressLinearColor: 'primary'
                },
                {
                    img: require('@/assets/images/faces/2.jpg'),
                    title: 'Zac Clarkson',
                    subTitle: '',
                    progressLinearColor: 'success'
                },
                {
                    img: require('@/assets/images/faces/3.jpg'),
                    title: 'Zac Clarkson',
                    subTitle: '',
                    progressLinearColor: 'danger'
                },
                {
                    img: require('@/assets/images/avatars/001-man.svg'),
                    title: 'Timothy clarkson',
                    subTitle: 'An experienced masseuse with 22 years in the business.',
                    progressLinearColor: 'primary'
                },
                {
                    img: require('@/assets/images/avatars/002-woman.svg'),
                    title: 'Zac Clarkson',
                    subTitle: '',
                    progressLinearColor: 'success'
                },
                {
                    img: require('@/assets/images/avatars/003-man-1.svg'),
                    title: 'Zac Clarkson',
                    subTitle: '',
                    progressLinearColor: 'danger'
                },

               */
            ],
        }
    },
    computed: {
        //...mapGetters([{types: 'refSystemServiceGroups'}]),             
        ...mapGetters(['refSystemServiceGroups', 'refSystemServices', 'myLocations','myCurrentLocation',      "getCart", "getCartList"]),
        total() {
            let selected = this.therapistServices.filter(s=>s.selected);
            let total=0;
            for(let s of selected) {
                for(let o of s.options) {
                    if(s.selectedDuration == o.duration) {
                        total += parseInt(o.price);
                    }
                }                 
            }
            return total;
        },
        items2() {
        let localePostfix = this.$root.$i18n.locale;
        const children = this.refSystemServiceGroups == null ? [] : this.refSystemServiceGroups.map(type => ({
          id: type.code,
          name: this.getName(type["title_"+localePostfix]),
          children: this.getChildren(type),
        }))
        //console.log(JSON.stringify(children, null, 2));
        return children;

        // return [{
        //   id: 1,
        //   name: 'All Breweries',
        //   children,
        // }]
      },
      myAugmentedLocations() {
        let rslt = [];
        let myLocs = this.myLocations;
        rslt.push(...myLocs);
        rslt.push({
            code: 'addnew',
            text: '+  Προσθήκη Νέας Διεύθυνσης'
        })
        return rslt;
      },
      shouldShowTree () {
        return this.breweries.length > 0 && !this.isLoading
      },
        // numberOfPages() {
        //     return Math.ceil(this.items.length / this.itemsPerPage)
        // },
        // filteredKeys() {
        //     return this.keys.filter((key) => key !== 'Name')
        // }
        columnSelector_Categories() {
            let localePostfix = this.$root.$i18n.locale;     
            let rslt = this.refSystemServiceGroups.map(
                g=>({
                    value: g.code,
                    text: g["title_"+localePostfix]
                })
            );            
            return rslt;
        },
        columnSelector_Services() {
            let localePostfix = this.$root.$i18n.locale;     
            let rslt = this.refSystemServices.map(
                g=>({
                    value: g.code,
                    text: g["title_"+localePostfix]
                })
            );
            if(this.selectedCategory) {
                rslt = this.refSystemServices
                        .filter(g=>g.service_type_group == this.selectedCategory)
                        .map(
                            g=>({
                                value: g.code,
                                text: g["title_"+localePostfix]
                            })
                        )
            }
            return rslt;
        },
        selectedServicesItems() {
            let localePostfix = this.$root.$i18n.locale;  
            let selectedKeys = this.selectedServices;
            let rslt = this.refSystemServices
            .filter(g=> selectedKeys.includes(g.code))
            .map(
                g=>({
                    value: g.code,
                    text: g["title_"+localePostfix]
                })
            );
            return rslt || [];
        }  
    },
    watch:  {
        myCurrentLocation(newVal) {
            if(newVal) {
                if(this.address_filter == null) {
                    this.address_filter = newVal.code;   
                    //this.previousAddressFilter = newVal.code.toString();
                }                
            }
        },
        address_filter(newVal, oldVal) {
            //console.log("SELECTED ADDRESS: " + oldVal);
            if(newVal == "addnew") {
                this.newLocation = this.createContactObject();
                this.addNewLocationDialog = true;       
                this.address_filter=null;         
                // alert(this.previousAddressFilter);
                // this.address_filter=this.previousAddressFilter;
                //this.previousAddressFilter = oldVal;
                //this.address_filter=oldVal;
            }
        },
    //     breweries (val) {
    //         this.types = val.reduce((acc, cur) => {
    //             const type = cur.brewery_type

    //             if (!acc.includes(type)) acc.push(type)

    //             return acc
    //         }, []).sort()
    //   },
    },
    methods: { 
        ...mapActions(["showErrorPopup", "hideErrorPopup", "initializeMyCurrentLocation",       "addToCart", "setCartTherapist", "removeFromCart", "clearCartItems"]),
        ...mapMutations(['setRefSystemServices', 'setRefSystemServiceGroups', 'setProfilePic', 'setMyLocations', 'setMyCurrentLocation']),       
        initiateCheckoutFlow(therapistCode) {
            //this.clearCartItems(); 
            this.setCartTherapist(therapistCode);
            this.checkoutdialog=true;
        },
        async saveNewLocation() {
            const rspAddLocation = await srvApi.patch(PATH.PROFILE_ADDLOCATION, this.newLocation);
            this.addNewLocationDialog=false;            
            this.setMyLocations(rspAddLocation.data.locations);            
            this.address_filter = rspAddLocation.data.locations[rspAddLocation.data.locations.length-1].code;
        },
        // nextPage() {
        //     if (this.page + 1 <= this.numberOfPages) this.page += 1
        // },
        // formerPage() {
        //     if (this.page - 1 >= 1) this.page -= 1
        // },
        // updateItemsPerPage(number) {
        //     this.itemsPerPage = number
        // }
        createContactObject() {            
            return {                
                primary_location: this.myLocations.length==0 ? true : false,
                position: null,
                title: "",
                prefecture: "", //"Περιφερειακή ενότητα Βορείου Τομέα Αθηνών",
                municipality: "", //"Νέα Ιωνία",
                street_address: "", //""Ομήρου 13",
                postal_code: "", //"142 33", //"12356",
                // phoneno: "", //"2102 795835",
                // email: "", //"alldayhome42@gmail.com",
                latcoord: null, //38.036843176315436, //37.9795244,    38.036843176315436, 23.758162196827396
                lngcoord: null, //23.758162196827396, //23.7359515
                extra_info: null,
                valstate: null
            }
        },
        setDefaultLocation(locationIndex) {    
            
            // this.customerLocations.forEach((loc,idx)=> {    
            //     let that = this;
            //     this.loading_setDefaultLocation = true;            
            //     if(idx==locationIndex) {
            //         setTimeout(() => { 
            //             loc.primary_location = true;
            //             that.loading_setDefaultLocation = false;
            //         }, 800);                    
            //     }
            //     else {
            //         loc.primary_location = false;
            //     }
            // });            
        },
        addServiceFilter(serviceCode) {
            if(!this.selectedServices.includes(serviceCode)){
                this.selectedServices.push(serviceCode);
            }
            else {
                //remove
                const idx = this.selectedServices.findIndex(s=>s==serviceCode);
                this.selectedServices.splice(idx,1);
            }
            
        },
        async searchTherapists() {
            const rspTherapists = await srvApi.get(PATH.BOOKING_THERAPISTS);
            this.items.push(...rspTherapists.data.map(t=>({
                img: getAssetUrl(t.profile_photo), //require('@/assets/images/avatars/001-man.svg'),
                title: t.firstname + " " + t.lastname,
                subTitle: t.short_description,
                progressLinearColor: 'primary',
                rating: t.rating,
                ratingCount: t.rating_count,
                details: {...t}
            })))
        },
        ...mapMutations(['setMyCurrentLocation']),
        addressSelected: function(loca) {
            const selectedLocation = this.myLocations.find(x=>x.code==loca);
            if(selectedLocation) {
                this.setMyCurrentLocation(selectedLocation);   
            }            
        },
        save: function (date) {
        var index = this.datesAll.findIndex(x => x===date)

        if (index === -1){
            this.datesAll.push(date);
        }
        else {
            this.datesAll.splice(index,1);
        }
        },
        fetch () {
            if (this.breweries.length) return

            this.breweries = [
            {
                id: 10,
                brewery_type: "Μασάζ",
                name: "Μασάζ Πόδια",
            },
            {
                id: 2,
                brewery_type: "Μασάζ",
                name: "Μασάζ Κεφάλι",
            },
            {
                id: 3,
                brewery_type: "Μασάζ",
                name: "Μασάζ Πλάτη",
            },
            {
                id: 4,
                brewery_type: "Μασάζ",
                name: "Shiatsu",
            },
            {
                id: 5,
                brewery_type: "Φυσιοθεραπεία",
                name: "Physio Μέση",
            },
            {
                id: 6,
                brewery_type: "Φυσιοθεραπεία",
                name: "Θεραπεία Αυχένα",
            },
            {
                id: 7,
                brewery_type: "Φυσιοθεραπεία",
                name: "Αποκατάσταση Γονάτου",
            },
            {
                id: 8,
                brewery_type: "Μασάζ",
                name: "Hot Stone Massage",
            },
            {
                id: 9,
                brewery_type: "Μασάζ",
                name: "CBD Oil Massage",
            },
            
        ]
        return;

        return fetch('https://api.openbrewerydb.org/breweries')
          .then(res => res.json())
          .then(data => (this.breweries = data))
          .catch(err => console.log(err))
      },
        // getChildren (type) {
        //     const breweries = []
        //     for (const brewery of this.breweries) {
        //         if (brewery.brewery_type !== type) continue
        //         breweries.push({
        //             ...brewery,
        //             name: this.getName(brewery.name),
        //         })
        //     }
        //     return breweries.sort((a, b) => {
        //         return a.name > b.name ? 1 : -1
        //     })
        // },
        getChildren (type) {
            let localePostfix = this.$root.$i18n.locale;
            const tempChildren = []
            if(this.refSystemServices) {
                for (const chi of this.refSystemServices) {
                    if (chi.service_type_group !== type.code) continue
                    tempChildren.push({
                        ...chi,
                        id: chi.code,
                        name: this.getName(chi["title_" + localePostfix]),
                    })
                }
            }            
            // return tempChildren.sort((a, b) => {
            //     return a.name > b.name ? 1 : -1
            // })
            return tempChildren;
        },
        getName (name) {
            return `${name.charAt(0).toUpperCase()}${name.slice(1)}`
        },

        getDateString: function(dt_string) {
            var weekday=new Array(7);
            weekday[1]="Mon";
            weekday[2]="Tue";
            weekday[3]="Wed";
            weekday[4]="Thu";
            weekday[5]="Fri";
            weekday[6]="Sat";
            weekday[0]="Sun";
            
            var dt = new Date(dt_string);
            const dayWeek = dt.getUTCDay();
            
            return `${weekday[dayWeek]}, ${dt.getUTCDate()}/${dt.getUTCMonth()+1}`;
        },

        initializeMyGeolocation(position) {
            console.log(JSON.stringify(position.coords));
        }
    },

        async mounted() {       
            //let localePostfix = this.$root.$i18n.locale;     
            //debugger;
            //console.log(JSON.stringify(this.refSystemServiceGroups));
            // this.columnSelector_Categories.push([...this.refSystemServiceGroups.map(
            //     g=>({
            //         value: g.code,
            //         text: g["title_"+localePostfix]
            //     })
            // )]);

            // function getLocation() {
                
            // }            
            if (navigator.geolocation) {                
                //navigator.geolocation.getCurrentPosition(showPosition);
                navigator.geolocation.getCurrentPosition(this.initializeMyGeolocation, null, {/*frequency:5000,*/ maximumAge: 0, timeout: 100, enableHighAccuracy:true});
            } else {
                console.log("Geolocation is not supported by this browser.");
            }

            await this.searchTherapists();
                        
        }
}
</script>
<style lang="scss">

// .v-chip__content > i {
//     visibility: hidden;
// }

// .v-chip__content > i:hover {
//     visibility: visible;
// }

.visibleonhoverr {    
    visibility: hidden;
}

.visibleonhoverr_container:hover .visibleonhoverr {
    visibility: visible !important;
}

// .ul-widget-profile-img {
//     position: relative;
// }
// .v-radio {
//     display: block !important;
// }
// .ul-widget-profile-img:after {
//     // content: "";
//     // position: absolute;
//     // top: 44px;
//     // left: 50%;
//     // width: 1px;
//     // height: calc(100% - 30px);
//     // background: #B3C0CE;
//     content: '';
//     position: absolute;
//     top: 44px;
//     left: 50%;
//     width: 1px;
//     height: calc(100% - 30px);
//     background: #b3c0ce;
// }
</style>
